<template>
  <!-- <div class="form-label-group" :class="className">
    <b-form-select
      @change="changeCC"
      v-model="value"
      :options="CCWithCCNameOptions"
      class="form-control"
    ></b-form-select>
    <img src="@/assets/images/earth.svg" />
  </div> -->
  <CustomSelectBox
    :className="className"
    :id="id"
    :value="theValue"
    :options="CCWithCCNameOptions"
    v-on:changeValue="theValue = $event"
    :title="$t('selectCountryCode')"
    :imgName="'earth.svg'"
  />
</template>

<script>
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";

export default {
  name: "TelPicker",
  components: { CustomSelectBox },
  data() {
    return {
      theValue: null,
      CCWithCCNameOptions: [
        // { value: "", text: this.$t("selectCountryCode") },
        // { value: "EG +20", text: this.$t("countryCodes.EG +20") },
        // { value: "GB +44", text: this.$t("countryCodes.uk") },
        // { value: "US +1", text: this.$t("countryCodes.usa") },
        // { value: "SA +966", text: this.$t("countryCodes.sa") },

        { value: "AF +93", text: this.$t("countryCodes.AF +93") },
        { value: "AX +358", text: this.$t("countryCodes.AX +358") },
        { value: "AL +355", text: this.$t("countryCodes.AL +355") },
        { value: "DZ +213", text: this.$t("countryCodes.DZ +213") },
        { value: "AS +1684", text: this.$t("countryCodes.AS +1684") },
        { value: "AD +376", text: this.$t("countryCodes.AD +376") },
        { value: "AO +244", text: this.$t("countryCodes.AO +244") },
        { value: "AI +1264", text: this.$t("countryCodes.AI +1264") },
        { value: "AQ +672", text: this.$t("countryCodes.AQ +672") },
        { value: "AG +1268", text: this.$t("countryCodes.AG +1268") },
        { value: "AR +54", text: this.$t("countryCodes.AR +54") },
        { value: "AM +374", text: this.$t("countryCodes.AM +374") },
        { value: "AW +297", text: this.$t("countryCodes.AW +297") },
        { value: "AU +61", text: this.$t("countryCodes.AU +61") },
        { value: "AT +43", text: this.$t("countryCodes.AT +43") },
        { value: "AZ +994", text: this.$t("countryCodes.AZ +994") },
        { value: "BS +1242", text: this.$t("countryCodes.BS +1242") },
        { value: "BH +973", text: this.$t("countryCodes.BH +973") },
        { value: "BD +880", text: this.$t("countryCodes.BD +880") },
        { value: "BB +1246", text: this.$t("countryCodes.BB +1246") },
        { value: "BY +375", text: this.$t("countryCodes.BY +375") },
        { value: "BE +32", text: this.$t("countryCodes.BE +32") },
        { value: "BZ +501", text: this.$t("countryCodes.BZ +501") },
        { value: "BJ +229", text: this.$t("countryCodes.BJ +229") },
        { value: "BM +1441", text: this.$t("countryCodes.BM +1441") },
        { value: "BT +975", text: this.$t("countryCodes.BT +975") },
        { value: "BO +591", text: this.$t("countryCodes.BO +591") },
        { value: "BA +387", text: this.$t("countryCodes.BA +387") },
        { value: "BW +267", text: this.$t("countryCodes.BW +267") },
        { value: "BR +55", text: this.$t("countryCodes.BR +55") },
        { value: "IO +246", text: this.$t("countryCodes.IO +246") },
        { value: "BN +673", text: this.$t("countryCodes.BN +673") },
        { value: "BG +359", text: this.$t("countryCodes.BG +359") },
        { value: "BF +226", text: this.$t("countryCodes.BF +226") },
        { value: "BI +257", text: this.$t("countryCodes.BI +257") },
        { value: "KH +855", text: this.$t("countryCodes.KH +855") },
        { value: "CM +237", text: this.$t("countryCodes.CM +237") },
        { value: "CA +1", text: this.$t("countryCodes.CA +1") },
        { value: "CV +238", text: this.$t("countryCodes.CV +238") },
        { value: "KY +345", text: this.$t("countryCodes.KY +345") },
        { value: "CF +236", text: this.$t("countryCodes.CF +236") },
        { value: "TD +235", text: this.$t("countryCodes.TD +235") },
        { value: "CL +56", text: this.$t("countryCodes.CL +56") },
        { value: "CN +86", text: this.$t("countryCodes.CN +86") },
        { value: "CX +61", text: this.$t("countryCodes.CX +61") },
        { value: "CC +61", text: this.$t("countryCodes.CC +61") },
        { value: "CO +57", text: this.$t("countryCodes.CO +57") },
        { value: "KM +269", text: this.$t("countryCodes.KM +269") },
        { value: "CG +242", text: this.$t("countryCodes.CG +242") },
        { value: "CD +243", text: this.$t("countryCodes.CD +243") },
        { value: "CK +682", text: this.$t("countryCodes.CK +682") },
        { value: "CR +506", text: this.$t("countryCodes.CR +506") },
        { value: "CI +225", text: this.$t("countryCodes.CI +225") },
        { value: "HR +385", text: this.$t("countryCodes.HR +385") },
        { value: "CU +53", text: this.$t("countryCodes.CU +53") },
        { value: "CY +357", text: this.$t("countryCodes.CY +357") },
        { value: "CZ +420", text: this.$t("countryCodes.CZ +420") },
        { value: "DK +45", text: this.$t("countryCodes.DK +45") },
        { value: "DJ +253", text: this.$t("countryCodes.DJ +253") },
        { value: "DM +1767", text: this.$t("countryCodes.DM +1767") },
        { value: "DO +1849", text: this.$t("countryCodes.DO +1849") },
        { value: "EC +593", text: this.$t("countryCodes.EC +593") },
        { value: "EG +20", text: this.$t("countryCodes.EG +20") },
        { value: "SV +503", text: this.$t("countryCodes.SV +503") },
        { value: "GQ +240", text: this.$t("countryCodes.GQ +240") },
        { value: "ER +291", text: this.$t("countryCodes.ER +291") },
        { value: "EE +372", text: this.$t("countryCodes.EE +372") },
        { value: "ET +251", text: this.$t("countryCodes.ET +251") },
        { value: "FK +500", text: this.$t("countryCodes.FK +500") },
        { value: "FO +298", text: this.$t("countryCodes.FO +298") },
        { value: "FJ +679", text: this.$t("countryCodes.FJ +679") },
        { value: "FI +358", text: this.$t("countryCodes.FI +358") },
        { value: "FR +33", text: this.$t("countryCodes.FR +33") },
        { value: "GF +594", text: this.$t("countryCodes.GF +594") },
        { value: "PF +689", text: this.$t("countryCodes.PF +689") },
        { value: "GA +241", text: this.$t("countryCodes.GA +241") },
        { value: "GM +220", text: this.$t("countryCodes.GM +220") },
        { value: "GE +995", text: this.$t("countryCodes.GE +995") },
        { value: "DE +49", text: this.$t("countryCodes.DE +49") },
        { value: "GH +233", text: this.$t("countryCodes.GH +233") },
        { value: "GI +350", text: this.$t("countryCodes.GI +350") },
        { value: "GR +30", text: this.$t("countryCodes.GR +30") },
        { value: "GL +299", text: this.$t("countryCodes.GL +299") },
        { value: "GD +1473", text: this.$t("countryCodes.GD +1473") },
        { value: "GP +590", text: this.$t("countryCodes.GP +590") },
        { value: "GU +1671", text: this.$t("countryCodes.GU +1671") },
        { value: "GT +502", text: this.$t("countryCodes.GT +502") },
        { value: "GG +44", text: this.$t("countryCodes.GG +44") },
        { value: "GN +224", text: this.$t("countryCodes.GN +224") },
        { value: "GW +245", text: this.$t("countryCodes.GW +245") },
        { value: "GY +595", text: this.$t("countryCodes.GY +595") },
        { value: "HT +509", text: this.$t("countryCodes.HT +509") },
        { value: "VA +379", text: this.$t("countryCodes.VA +379") },
        { value: "HN +504", text: this.$t("countryCodes.HN +504") },
        { value: "HK +852", text: this.$t("countryCodes.HK +852") },
        { value: "HU +36", text: this.$t("countryCodes.HU +36") },
        { value: "IS +354", text: this.$t("countryCodes.IS +354") },
        { value: "IN +91", text: this.$t("countryCodes.IN +91") },
        { value: "ID +62", text: this.$t("countryCodes.ID +62") },
        { value: "IR +98", text: this.$t("countryCodes.IR +98") },
        { value: "IQ +964", text: this.$t("countryCodes.IQ +964") },
        { value: "IE +353", text: this.$t("countryCodes.IE +353") },
        { value: "IM +44", text: this.$t("countryCodes.IM +44") },
        { value: "IL +972", text: this.$t("countryCodes.IL +972") },
        { value: "IT +39", text: this.$t("countryCodes.IT +39") },
        { value: "JM +1876", text: this.$t("countryCodes.JM +1876") },
        { value: "JP +81", text: this.$t("countryCodes.JP +81") },
        { value: "JE +44", text: this.$t("countryCodes.JE +44") },
        { value: "JO +962", text: this.$t("countryCodes.JO +962") },
        { value: "KZ +77", text: this.$t("countryCodes.KZ +77") },
        { value: "KE +254", text: this.$t("countryCodes.KE +254") },
        { value: "KI +686", text: this.$t("countryCodes.KI +686") },
        { value: "KP +850", text: this.$t("countryCodes.KP +850") },
        { value: "KR +82", text: this.$t("countryCodes.KR +82") },
        { value: "KW +965", text: this.$t("countryCodes.KW +965") },
        { value: "KG +996", text: this.$t("countryCodes.KG +996") },
        { value: "LA +856", text: this.$t("countryCodes.LA +856") },
        { value: "LV +371", text: this.$t("countryCodes.LV +371") },
        { value: "LB +961", text: this.$t("countryCodes.LB +961") },
        { value: "LS +266", text: this.$t("countryCodes.LS +266") },
        { value: "LR +231", text: this.$t("countryCodes.LR +231") },
        { value: "LY +218", text: this.$t("countryCodes.LY +218") },
        { value: "LI +423", text: this.$t("countryCodes.LI +423") },
        { value: "LT +370", text: this.$t("countryCodes.LT +370") },
        { value: "LU +352", text: this.$t("countryCodes.LU +352") },
        { value: "MO +853", text: this.$t("countryCodes.MO +853") },
        { value: "MK +389", text: this.$t("countryCodes.MK +389") },
        { value: "MG +261", text: this.$t("countryCodes.MG +261") },
        { value: "MW +265", text: this.$t("countryCodes.MW +265") },
        { value: "MY +60", text: this.$t("countryCodes.MY +60") },
        { value: "MV +960", text: this.$t("countryCodes.MV +960") },
        { value: "ML +223", text: this.$t("countryCodes.ML +223") },
        { value: "MT +356", text: this.$t("countryCodes.MT +356") },
        { value: "MH +692", text: this.$t("countryCodes.MH +692") },
        { value: "MQ +596", text: this.$t("countryCodes.MQ +596") },
        { value: "MR +222", text: this.$t("countryCodes.MR +222") },
        { value: "MU +230", text: this.$t("countryCodes.MU +230") },
        { value: "YT +262", text: this.$t("countryCodes.YT +262") },
        { value: "MX +52", text: this.$t("countryCodes.MX +52") },
        { value: "FM +691", text: this.$t("countryCodes.FM +691") },
        { value: "MD +373", text: this.$t("countryCodes.MD +373") },
        { value: "MC +377", text: this.$t("countryCodes.MC +377") },
        { value: "MN +976", text: this.$t("countryCodes.MN +976") },
        { value: "ME +382", text: this.$t("countryCodes.ME +382") },
        { value: "MS +1664", text: this.$t("countryCodes.MS +1664") },
        { value: "MA +212", text: this.$t("countryCodes.MA +212") },
        { value: "MZ +258", text: this.$t("countryCodes.MZ +258") },
        { value: "MM +95", text: this.$t("countryCodes.MM +95") },
        { value: "NA +264", text: this.$t("countryCodes.NA +264") },
        { value: "NR +674", text: this.$t("countryCodes.NR +674") },
        { value: "NP +977", text: this.$t("countryCodes.NP +977") },
        { value: "NL +31", text: this.$t("countryCodes.NL +31") },
        { value: "AN +599", text: this.$t("countryCodes.AN +599") },
        { value: "NC +687", text: this.$t("countryCodes.NC +687") },
        { value: "NZ +64", text: this.$t("countryCodes.NZ +64") },
        { value: "NI +505", text: this.$t("countryCodes.NI +505") },
        { value: "NE +227", text: this.$t("countryCodes.NE +227") },
        { value: "NG +234", text: this.$t("countryCodes.NG +234") },
        { value: "NU +683", text: this.$t("countryCodes.NU +683") },
        { value: "NF +672", text: this.$t("countryCodes.NF +672") },
        { value: "MP +1670", text: this.$t("countryCodes.MP +1670") },
        { value: "NO +47", text: this.$t("countryCodes.NO +47") },
        { value: "OM +968", text: this.$t("countryCodes.OM +968") },
        { value: "PK +92", text: this.$t("countryCodes.PK +92") },
        { value: "PW +680", text: this.$t("countryCodes.PW +680") },
        { value: "PS +970", text: this.$t("countryCodes.PS +970") },
        { value: "PA +507", text: this.$t("countryCodes.PA +507") },
        { value: "PG +675", text: this.$t("countryCodes.PG +675") },
        { value: "PY +595", text: this.$t("countryCodes.PY +595") },
        { value: "PE +51", text: this.$t("countryCodes.PE +51") },
        { value: "PH +63", text: this.$t("countryCodes.PH +63") },
        { value: "PN +872", text: this.$t("countryCodes.PN +872") },
        { value: "PL +48", text: this.$t("countryCodes.PL +48") },
        { value: "PT +351", text: this.$t("countryCodes.PT +351") },
        { value: "PR +1939", text: this.$t("countryCodes.PR +1939") },
        { value: "QA +974", text: this.$t("countryCodes.QA +974") },
        { value: "RO +40", text: this.$t("countryCodes.RO +40") },
        { value: "RU +7", text: this.$t("countryCodes.RU +7") },
        { value: "RW +250", text: this.$t("countryCodes.RW +250") },
        { value: "RE +262", text: this.$t("countryCodes.RE +262") },
        { value: "BL +590", text: this.$t("countryCodes.BL +590") },
        { value: "SH +290", text: this.$t("countryCodes.SH +290") },
        { value: "KN +1869", text: this.$t("countryCodes.KN +1869") },
        { value: "LC +1758", text: this.$t("countryCodes.LC +1758") },
        { value: "MF +590", text: this.$t("countryCodes.MF +590") },
        { value: "PM +508", text: this.$t("countryCodes.PM +508") },
        { value: "VC +1784", text: this.$t("countryCodes.VC +1784") },
        { value: "WS +685", text: this.$t("countryCodes.WS +685") },
        { value: "SM +378", text: this.$t("countryCodes.SM +378") },
        { value: "ST +239", text: this.$t("countryCodes.ST +239") },
        { value: "SA +966", text: this.$t("countryCodes.SA +966") },
        { value: "SN +221", text: this.$t("countryCodes.SN +221") },
        { value: "RS +381", text: this.$t("countryCodes.RS +381") },
        { value: "SC +248", text: this.$t("countryCodes.SC +248") },
        { value: "SL +232", text: this.$t("countryCodes.SL +232") },
        { value: "SG +65", text: this.$t("countryCodes.SG +65") },
        { value: "SK +421", text: this.$t("countryCodes.SK +421") },
        { value: "SI +386", text: this.$t("countryCodes.SI +386") },
        { value: "SB +677", text: this.$t("countryCodes.SB +677") },
        { value: "SO +252", text: this.$t("countryCodes.SO +252") },
        { value: "ZA +27", text: this.$t("countryCodes.ZA +27") },
        { value: "SS +211", text: this.$t("countryCodes.SS +211") },
        { value: "GS +500", text: this.$t("countryCodes.GS +500") },
        { value: "ES +34", text: this.$t("countryCodes.ES +34") },
        { value: "LK +94", text: this.$t("countryCodes.LK +94") },
        { value: "SD +249", text: this.$t("countryCodes.SD +249") },
        { value: "SR +597", text: this.$t("countryCodes.SR +597") },
        { value: "SJ +47", text: this.$t("countryCodes.SJ +47") },
        { value: "SZ +268", text: this.$t("countryCodes.SZ +268") },
        { value: "SE +46", text: this.$t("countryCodes.SE +46") },
        { value: "CH +41", text: this.$t("countryCodes.CH +41") },
        { value: "SY +963", text: this.$t("countryCodes.SY +963") },
        { value: "TW +886", text: this.$t("countryCodes.TW +886") },
        { value: "TJ +992", text: this.$t("countryCodes.TJ +992") },
        { value: "TZ +255", text: this.$t("countryCodes.TZ +255") },
        { value: "TH +66", text: this.$t("countryCodes.TH +66") },
        { value: "TL +670", text: this.$t("countryCodes.TL +670") },
        { value: "TG +228", text: this.$t("countryCodes.TG +228") },
        { value: "TK +690", text: this.$t("countryCodes.TK +690") },
        { value: "TO +676", text: this.$t("countryCodes.TO +676") },
        { value: "TT +1868", text: this.$t("countryCodes.TT +1868") },
        { value: "TN +216", text: this.$t("countryCodes.TN +216") },
        { value: "TR +90", text: this.$t("countryCodes.TR +90") },
        { value: "TM +993", text: this.$t("countryCodes.TM +993") },
        { value: "TC +1649", text: this.$t("countryCodes.TC +1649") },
        { value: "TV +688", text: this.$t("countryCodes.TV +688") },
        { value: "UG +256", text: this.$t("countryCodes.UG +256") },
        { value: "UA +380", text: this.$t("countryCodes.UA +380") },
        { value: "AE +971", text: this.$t("countryCodes.AE +971") },
        { value: "GB +44", text: this.$t("countryCodes.GB +44") },
        { value: "US +1", text: this.$t("countryCodes.US +1") },
        { value: "UY +598", text: this.$t("countryCodes.UY +598") },
        { value: "UZ +998", text: this.$t("countryCodes.UZ +998") },
        { value: "VU +678", text: this.$t("countryCodes.VU +678") },
        { value: "VE +58", text: this.$t("countryCodes.VE +58") },
        { value: "VN +84", text: this.$t("countryCodes.VN +84") },
        { value: "VG +1284", text: this.$t("countryCodes.VG +1284") },
        { value: "VI +1340", text: this.$t("countryCodes.VI +1340") },
        { value: "WF +681", text: this.$t("countryCodes.WF +681") },
        { value: "YE +967", text: this.$t("countryCodes.YE +967") },
        { value: "ZM +260", text: this.$t("countryCodes.ZM +260") },
        { value: "ZW +263", text: this.$t("countryCodes.ZW +263") },
      ],
    };
  },
  methods: {
    async changeCC() {
      this.$emit("changeCC", this.value);
    },
  },
  props: {
    className: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "TelPicker",
    },
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: function (val) {
      this.theValue = val;
    },
    theValue: function (val) {
      this.$emit("changeCC", val);
      // if (!val) {
      //   this.$emit("changeCC", "");
      // } else if (val != this.value) {
      //   this.$emit("changeCC", val ? val : "");
      // }
    },
  },
  created() {
    this.theValue = this.value;
  },
};
</script>
